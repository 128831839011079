/* eslint-disable */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useState, useEffect} from 'react';
import ReactAudioPlayer from 'react-audio-player';
import {ImageMap} from '@qiuz/react-image-map';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';
// import PropTypes from 'prop-types';

import styles from './index.module.scss';

const SessionModule = () => {
  const {hash} = useParams();
  const [session, setSession] = useState(null);
  const [error, setError] = useState(false);
  useEffect(() => {
    (async () => {
      if (!hash) {
        return;
      }
      try {
        const rsp = await axios.get(
          `https://api.almaceleste.cl/front/session/${hash}`
        );
        if (rsp && rsp.data) {
          setSession(rsp.data);
        } else {
          console.log('NO');
          setSession(null);
          setError(true);
        }
      } catch (e) {
        setError(true);
      }
    })();
  }, [hash]);
  if (error) {
    return (
      <div className={styles.notFound}>
        <div>
          <img
            src='/assets/almaceleste/Almaceleste-03.png'
            alt=''
            style={{width: 100, marginBottom: 20}}
          />
          <br />
          Sesi�n no encontrada
        </div>
      </div>
    );
  }
  if (!session) {
    return null;
  }
  const carta =
    session &&
    session.files &&
    session.files.find((x) => x.title.toLowerCase().match(/^carta/));
  const infografia =
    session &&
    session.files &&
    session.files.find((x) => x.title.toLowerCase().match(/^infog/));
  const audios =
    session &&
    session.files &&
    session.files.filter((x) => x.title.toLowerCase().match(/^audio/));
  const videos =
    session &&
    session.files &&
    session.files.filter((x) => x.title.toLowerCase().match(/^video/));

  return (
    <div className={styles.cnt}>
      <div className={styles.inner}>
        <img
          src='/assets/banner-header.png?v=2'
          alt=''
          className={styles.header}
        />
        <div
          className={[
            styles.files,
            session.type === 'transitos_planetarios'
              ? styles.transitos_planetarios
              : styles.normal,
          ].join(' ')}
        >
          {session.type !== 'transitos_planetarios' && (
            <>
              <div className={styles.carta}>
                {carta && (
                  <>
                    <img src={carta.cdn_url} alt='' />
                    <a
                      href={carta.cdn_url}
                      target='_ac_img'
                      className={styles.downloadButton}
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        style={{marginRight: 10}}
                      />{' '}
                      Descargar
                    </a>
                  </>
                )}
              </div>
            </>
          )}
          {session.type !== 'transitos_planetarios' && (
            <>
              <div className={styles.infografia}>
                {infografia && (
                  <>
                    <img src={infografia.cdn_url} alt='' />

                    <a
                      href={infografia.cdn_url}
                      target='_ac_img'
                      className={styles.downloadButton}
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        style={{marginRight: 10}}
                      />{' '}
                      Descargar
                    </a>
                  </>
                )}
              </div>
            </>
          )}
          {session.type === 'transitos_planetarios' && (
            <div className={styles.video}>
              {videos &&
                videos.map((video) => (
                  <ReactPlayer
                    config={{
                      file: {attributes: {controlsList: 'nodownload'}},
                    }}
                    controlsList='nodownload'
                    onContextMenu={(e) => e.preventDefault()}
                    url={video.cdn_url}
                    style={{width: '100%', height: 400}}
                    width='100%'
                    controls
                  />
                ))}
            </div>
          )}
          <div className={styles.audio}>
            {audios &&
              audios.map((audio) => (
                <ReactAudioPlayer src={audio.cdn_url} controls />
              ))}
          </div>
        </div>
        {session.assigned_to && (
          <div className={styles.signature}>
            <img
              src={`/assets/cierres-v2/user-${session.assigned_to}.png`}
              alt=''
            />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <ImageMap
          className={styles.imgFooter}
          src='/assets/banner-footer-2.jpeg?v=2'
          map={[
            {
              left: '5%',
              top: '50%',
              height: '39%',
              width: '34%',
              style: {cursor: 'pointer'},
              onClick: () => {},
            },
            {
              left: '5%',
              top: '10%',
              height: '39%',
              width: '34%',
              style: {cursor: 'pointer'},
              onClick: () => {},
            },
          ]}
          onMapClick={(area, index) => {
            if (index === 0) {
              window.open(
                'https://chat.whatsapp.com/EIr5m53LP8V374KDpANo3g',
                '_ac_tg'
              );
            }
            if (index === 1) {
              window.open(
                'https://www.instagram.com/almaceleste.cl/',
                '_ac_ig'
              );
            }
          }}
        />
      </div>
    </div>
  );
};

SessionModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
};

export default SessionModule;
