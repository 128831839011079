export const setUser = (user) => {
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
  }
};

export const setKey = (key, value) => {
  localStorage.setItem(key, value);
};

export const getKey = (key) => localStorage.getItem(key);

export const setToken = (token) => {
  if (token) {
    localStorage.setItem('token', token);
  }
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('__state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('__state', serializedState);
  } catch (e) {
    // ignore write errors
  }
};

export const getToken = () => {
  const state = loadState();
  if (!state) {
    return null;
  }
  return 'auth' in state && 'token' in state.auth ? state.auth.token : null;
};

export const getUser = () => {
  const state = loadState();
  if (!state) {
    return null;
  }
  return 'auth' in state && 'user' in state.auth ? state.auth.user : null;
};
export const removeToken = () => {
  localStorage.removeItem('token');
};
export const removeUser = () => {
  removeToken();
  localStorage.removeItem('user');
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const logout = () => {
  clearLocalStorage();
};
export const defaultLimit = 10;
