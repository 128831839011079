/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { ImageMap } from '@qiuz/react-image-map';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../environment';
// import PropTypes from 'prop-types';

import styles from './index.module.scss';
import Item from '../../components/Item';

const LibraryModule = () => {
  const { hash, secret } = useParams();
  const [library, setLibrary] = useState(null);
  useEffect(() => {
    (async () => {
      if (!hash) {
        return;
      }
      const rsp = await axios.get(`${API_BASE_URL}/public/library/${hash}/${secret}`);
      if (rsp && rsp.data) {
        setLibrary(rsp.data);
      } else {
        setLibrary(null);
      }
    })();
  }, [hash]);
  if (!library) {
    return null;
  }
  return (
    <div className={styles.cnt}>
      <div className={styles.inner}>
        <img src="/assets/library-header.png?v=2" alt="" className={styles.header} />
        <div className={styles.items}>
          {library?.items?.map((x) => <Item item={x} />)}
        </div>
      </div>
      <div className={styles.footer}>
        <ImageMap
          className={styles.imgFooter}
          src="/assets/banner-footer.png?v=2"
          map={[
            {
              left: '5%',
              top: '50%',
              height: '39%',
              width: '34%',
              style: { cursor: 'pointer' },
              onClick: () => { },
            },
            {
              left: '5%',
              top: '10%',
              height: '39%',
              width: '34%',
              style: { cursor: 'pointer' },
              onClick: () => { },
            },
          ]}
          onMapClick={(area, index) => {
            if (index === 0) {
              window.open('https://t.me/+1MZW6UE7VKY1NDBh', '_ac_tg');
            }
            if (index === 1) {
              window.open('https://www.instagram.com/almaceleste.cl/', '_ac_ig');
            }
          }}
        />
      </div>
    </div>
  );
};

LibraryModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
};

export default LibraryModule;
