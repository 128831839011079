/* eslint-disable */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {useLocation} from 'react-router-dom';

import styles from './index.module.scss';

const ImageModule = () => {
  const q = useLocation().search;
  const url = new URLSearchParams(q).get('url');
  const id = new URLSearchParams(q).get('id');
  let imgUrl = null;
  if (id) {
    imgUrl = `https://drive.google.com/u/0/uc?id=${id}&t=1&confirm=t`;
  }
  if (url) {
    imgUrl = url;
  }

  if (!imgUrl) {
    return null;
  }
  return (
    <div className={styles.cnt}>
      <img src={imgUrl} alt='' className={styles.img} />
    </div>
  );
};

export default ImageModule;
