/* eslint-disable no-restricted-syntax */
import React from 'react';
import { Routes as Switch, BrowserRouter, Route } from 'react-router-dom';

import BlankLayout from './layouts/BlankLayout';
import CourseModule from './modules/CourseModule';

import HomeModule from './modules/HomeModule';
import ImageModule from './modules/ImageModule';
import SessionModule from './modules/SessionModule';
import LibraryModule from './modules/LibraryModule';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route
        path="/"
        element={(
          <BlankLayout>
            <HomeModule />
            {' '}
          </BlankLayout>
        )}
      />
      <Route
        path="/sesion/:hash"
        element={(
          <BlankLayout>
            <SessionModule />
            {' '}
          </BlankLayout>
        )}
      />
      <Route
        path="/sesion/:hash"
        element={(
          <BlankLayout>
            <SessionModule />
            {' '}
          </BlankLayout>
        )}
      />
      <Route
        path="/biblioteca/:hash/:secret"
        element={(
          <BlankLayout>
            <LibraryModule />
            {' '}
          </BlankLayout>
        )}
      />
      <Route
        path="/grabacion/:hash"
        element={(
          <BlankLayout>
            <SessionModule />
            {' '}
          </BlankLayout>
        )}
      />
      <Route
        path="/curso/:slug/:hash"
        element={(
          <BlankLayout>
            <CourseModule />
            {' '}
          </BlankLayout>
        )}
      />
      <Route
        path="/image"
        element={(
          <BlankLayout>
            <ImageModule />
          </BlankLayout>
        )}
      />
    </Switch>
  </BrowserRouter>
);

Routes.defaultProps = {
};

Routes.propTypes = {
};

export default Routes;
