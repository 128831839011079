/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faDownload } from '@fortawesome/free-solid-svg-icons';
import numeral from 'numeral';
// import PropTypes from 'prop-types';

import styles from './index.module.scss';

const Item = ({ item }) => (
  <div className={styles.cnt}>
    <div className={styles.img}>
      {item.data?.content?.portada?.content?.webContentLink
        && (
          <img
            className={styles.img}
            src={`https://th.qoopit.io/unsafe/fit-in/500x500/filters:fill(white,false)/${encodeURIComponent(item.data?.content?.portada?.content?.webContentLink)}`}
            alt=""
          />
        )}
    </div>
    <div className={styles.info}>
      <div className={styles.title}>
        {item.title}
      </div>
      {item.description
        && (
          <div className={styles.description}>
            {item.description}
          </div>
        )}
    </div>
    <div className={styles.actions}>
      {item.data?.content?.downloadable?.content?.webContentLink
        && (
          <a
            className={styles.button}
            href={item.data?.content?.downloadable?.content?.webContentLink}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faDownload} />
            {' '}
            Descargar PDF

          </a>

        )}
      {item.available_for_sell
        && (
          <a
            className={[styles.button, styles.purchase_button].join(' ')}
            onClick={() => {
              const text = `Hola, me interesa comprar "${item.title}"`;
              window.location = `https://wa.me/+56962886710?text=${text}`;
            }}
          >
            <FontAwesomeIcon icon={faBook} />
            {' '}
            Comprar libro (
            {numeral(item.sell_price).format('$0,0')}
            )

          </a>

        )}
    </div>
  </div>
);

Item.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Item;
