/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import ReactPlayer from 'react-player';
import moment from 'moment';
import isMobile from 'is-mobile';
import { useParams } from 'react-router-dom';
// import PropTypes from 'prop-types';
import * as coursesService from '../../services/courses';
import { nl2br } from '../../helpers/utils';
import { setKey, getKey } from '../../helpers/storage';

import styles from './index.module.scss';

const CourseModule = () => {
  const { hash } = useParams();
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState([]);
  const [error, setError] = useState(false);
  const refVideo = useRef();
  const refVideoCurrent = refVideo.current;
  const [currentLesson, setCurrentLesson] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(null);
  const [startSeconds, setStartSeconds] = useState(null);
  const [lessonVideoIndex, setLessonVideoIndex] = useState(0);

  const loadCourse = async (showLoader) => {
    if (showLoader) {
      setLoading(true);
    }
    try {
      const rsp = await coursesService.get(hash);
      if (rsp && rsp.status) {
        const loadedCourse = rsp.data;
        loadedCourse.lessons = loadedCourse.lessons.map((l) => {
          const videos = l?.data?.videos && l.data?.videos.length
            ? l.data?.videos
            : l?.files.filter((x) => x.mimeType === 'video/mp4').sort((a, b) => (a.title < b.title ? -1 : 1));

          // console.log('x videos', videos);

          return {
            ...l,
            videos,
            otherFiles: l?.files && l?.files.filter((x) => x.mimeType !== 'video/mp4').sort((a, b) => (a.title < b.title ? -1 : 1)),
          };
        });
        setCourse(loadedCourse);
        if (rsp.data.lessons) {
          const st = getKey(`_st_${rsp.data.id}`);
          if (st) {
            // eslint-disable-next-line no-unused-vars
            const [storedCurrentLessonId, storedLessonVideoIndex, storedPlayedSeconds] = st.split(',');
            const cl = rsp.data.lessons.find((x) => x.id === parseInt(storedCurrentLessonId, 10));
            if (cl) {
              setCurrentLesson(cl);
              setStartSeconds(parseInt(storedPlayedSeconds, 10));
              setLessonVideoIndex(parseInt(storedLessonVideoIndex, 10));
            } else {
              setCurrentLesson(rsp.data.lessons[0]);
              setStartSeconds(0);
              setLessonVideoIndex(0);
            }
            // setStartSeconds(0);
            // setLessonVideoIndex(0);
          } else {
            setCurrentLesson(rsp.data.lessons[0]);
            setStartSeconds(0);
            setLessonVideoIndex(0);
          }
        } else {
          setCurrentLesson(null);
        }
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const startLesson = (lesson) => {
    // console.log('startLesson');
    setCurrentLesson(lesson);
    setLessonVideoIndex(0);
    setPlayedSeconds(0);
  };
  const startVideo = (index) => {
    setLessonVideoIndex(index);
    setPlayedSeconds(0);
  };

  const onVideoProgress = ({ playedSeconds: newPlayedSeconds }) => {
    console.info('onVideoProgress', playedSeconds);
    setPlayedSeconds(newPlayedSeconds);
    setPlaying(true);
  };
  const onVideoEnded = () => {
    // console.log('onVideoEnded');
    const lessonVideos = currentLesson?.videos;
    if (lessonVideos.length > (parseInt(lessonVideoIndex, 10) + 1)) {
      setLessonVideoIndex(parseInt(lessonVideoIndex, 10) + 1);
    } else {
      const nextLesson = course.lessons.find((l) => l.id > currentLesson.id);
      if (nextLesson) {
        setCurrentLesson(nextLesson);
      }
    }
  };

  useEffect(() => {
    // console.log('useEffect [currentLesson?.id]', currentLesson);
    if (currentLesson) {
      setPlayedSeconds(0);
      window.scrollTo(0, 0);
    }
  }, [currentLesson?.id]);

  useEffect(() => {
    /* console.log(
    'useEffect [currentLesson?.id, lessonVideoIndex, playedSeconds]',
     currentLesson, lessonVideoIndex, playedSeconds); */

    if (!currentLesson || playedSeconds <= 0) {
      return;
    }
    const st = getKey(`_st_${currentLesson.course_id}`);
    if (st) {
      // eslint-disable-next-line no-unused-vars
      const [storedCurrentLessonId, storedLessonVideoIndex, storedPlayedSeconds] = st.split(',');
      if (currentLesson.id >= parseInt(storedCurrentLessonId, 10)
        && lessonVideoIndex >= parseInt(storedLessonVideoIndex, 10)
        && (
          lessonVideoIndex > parseInt(storedLessonVideoIndex, 10)
          || playedSeconds >= parseInt(storedPlayedSeconds, 10)
        )
      ) {
        setKey(`_st_${currentLesson.course_id}`, `${currentLesson.id},${lessonVideoIndex},${lessonVideoIndex > parseInt(storedLessonVideoIndex, 10) ? 0 : playedSeconds}`);
      }
    } else {
      setKey(`_st_${currentLesson.course_id}`, `${currentLesson.id},${lessonVideoIndex},${playedSeconds}`);
    }
  }, [currentLesson?.id, lessonVideoIndex, playedSeconds]);

  useEffect(() => {
    // console.log('useEffect [refVideoCurrent, startSeconds]', refVideoCurrent, startSeconds);
    if (startSeconds && refVideoCurrent) {
      refVideo?.current?.seekTo(startSeconds);
      // setStartSeconds(null);
    }
  }, [refVideoCurrent, startSeconds]);

  useEffect(() => {
    // console.log('useEffect [hash]', hash);
    loadCourse(true);
  }, [hash]);

  const videoUrlParam = useMemo(() => {
    if (!currentLesson || !currentLesson.videos || !currentLesson.videos[lessonVideoIndex]) {
      return null;
    }
    return currentLesson.videos[lessonVideoIndex].url
      ? currentLesson.videos[lessonVideoIndex].url
      : [{

        src:
          currentLesson.videos[lessonVideoIndex].cdn_url,
        type: currentLesson.videos[lessonVideoIndex].mimeType,
      }];
  }, [currentLesson?.id, lessonVideoIndex]);

  if (error) {
    return (
      <div className={styles.notFound}>
        <div>
          <img src="/assets/almaceleste/Almaceleste-03.png" alt="" style={{ width: 100, marginBottom: 20 }} />
          <br />
          Curso no encontrado
        </div>
      </div>
    );
  }

  if (loading || !currentLesson) {
    return null;
  }

  return (
    <div className={styles.cnt}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src="/assets/almaceleste/Almaceleste-03.png" alt="" />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.navplayer}>
          <div className={[styles.video, 'react-player'].join(' ')}>
            {videoUrlParam
              && (
                <ReactPlayer
                  ref={refVideo}
                  config={{
                    file: { attributes: { controlsList: 'nodownload' } },
                    vimeo: {
                      title: '',
                      playerOptions: {
                        title: false,
                        responsive: true,
                        controls: false,
                        dnt: true,
                        byline: false,
                        playsinline: true,
                        background: true,
                      },
                    },
                    youtube: {
                      playerVars: {
                        disablekb: 1,
                        modestbranding: 1,
                        fs: 0,
                        showinfo: 0,
                        rel: 0,
                      },
                    },
                  }}
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                  playing={playing}
                  url={videoUrlParam}
                  style={{ width: '100%', height: 800 }}
                  onProgress={onVideoProgress}
                  onEnded={onVideoEnded}
                  width="100%"
                  height={isMobile() ? window.innerWidth * 0.52 : 383}
                  controls
                />
              )}
          </div>
          {currentLesson?.videos && currentLesson?.videos.length > 1
            && (
              <div className={styles.videos}>
                {currentLesson.videos && currentLesson.videos.map((v, i) => (
                  <div
                    onClick={() => startVideo(i)}
                    className={[styles.videoPart, i === lessonVideoIndex ? styles.current : ''].join(' ')}
                  >
                    {v.useTitle ? v.useTitle : v.title?.split('.').slice(0, -1).join(' ')}
                  </div>
                ))}
              </div>
            )}

        </div>
        <div className={styles.info}>
          <div className={styles.name}>{currentLesson.name}</div>
          {currentLesson.start_date && <div className={styles.date}>{moment(currentLesson.start_date).format('DD/MM, YYYY')}</div>}
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: nl2br(currentLesson.description) }}
          />
          {currentLesson.otherFiles && currentLesson.otherFiles.length > 0
            && (
              <div className={styles.otherFiles}>
                <div className={styles.title}>Material complementario</div>
                <div className={styles.contentF}>
                  {currentLesson.otherFiles.map((f) => (
                    <div className={styles.file}>
                      <div className={styles.icon}>
                        <a href={f.cdn_url} target="_file"><img src={f.iconLink} alt="" /></a>
                      </div>
                      <div className={styles.fileTitle}>
                        <a href={f.cdn_url} target="_file">{f.title}</a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
        </div>
        <div className={styles.sidebar}>
          <div className={styles.lessons}>
            <div className={styles.title}>Clases</div>
            <div className={styles.list}>
              {course.lessons.map((lesson) => (
                <div
                  className={[styles.lesson, lesson.id === currentLesson.id ? styles.current : ''].join(' ')}
                  onClick={() => startLesson(lesson)}
                >
                  <div className={styles.name}>
                    {lesson.name}
                  </div>
                  {lesson.start_date && <div className={styles.date}>{moment(lesson.start_date).format('DD/MM, YYYY')}</div>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default CourseModule;
