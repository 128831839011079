import React from 'react';

import styles from './index.module.scss';

const HomeModule = () => (
  <div className={styles.cnt}>
    <div className={styles.logo}>
      <img src="/assets/almaceleste/Almaceleste-03.png" alt="" />
    </div>
  </div>
);

HomeModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
};

export default HomeModule;
