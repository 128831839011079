export default (client, defaultParams) => ({
  login: (payload) => (
    client('/login', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  forgetPassword: (payload) => (
    client('/user/forget_password', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  changePassword: (payload) => (
    client('/user/change_password', {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
});
