import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const get = async (hash) => executeAndWaitResponse(
  () => apiClient.courses.get(hash),
);

export default {
  get,
};
