/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const BlankLayout = ({
  children,
}) => (
  <div className={styles.root}>
    <div className={styles.container}>
      {children}
    </div>
  </div>

);

BlankLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default BlankLayout;
