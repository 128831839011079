export default (client, defaultParams) => ({
  get: (hash) => (
    client(`/session/${hash}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
});
